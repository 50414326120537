import { useState, useEffect } from "react";
import MobileNumber from "../component/login/MobileNumber";
import OTP from "../component/login/OTP";
import LoginSkin from "../component/login/LoginSkin";
import { useAuthContext } from "../context/auth";
import { useHistory } from "react-router";

import { getAuth } from "firebase/auth";

const Login = () => {
    const history = useHistory()
    const auth = useAuthContext();
    
    // useEffect(() => {
    //     if (!getAuth().currentUser)
    //         auth.signOut()
    // }, [getAuth().currentUser]);

    const [mobileNumber, setMobileNumber] = useState(null);
    return (
        <LoginSkin>
            {mobileNumber ? <OTP mobileNumber={mobileNumber} setMobileNumber={setMobileNumber} /> : <MobileNumber mobileNumber={mobileNumber} setMobileNumber={setMobileNumber} />}
        </LoginSkin>
    );
}

export default Login;